import * as dotenv from 'dotenv'
dotenv.config()

const service = {
  NAME: process.env.REACT_APP_SERVICE_NAME || 'vehicle-submission-web',
}

const authService = {
  URL: process.env.REACT_APP_TS_AUTHENTICATION_URL,
  AUTH_PROJECT: process.env.REACT_APP_AUTH_PROJECT,
}

const restful = {
  URL: process.env.REACT_APP_TS_RESTFUL_URL,
  PROJECT: process.env.REACT_APP_SUBMISSION_PROJECT,
}

const uploadService = {
  URL: process.env.REACT_APP_TS_UPLOAD_URL,
}

const social = {
  GOOGLE_APP_ID: process.env.REACT_APP_SOCIAL_GOOGLE_APP_ID || '',
  FACEBOOK_APP_ID: process.env.REACT_APP_SOCIAL_FACEBOOK_APP_ID || '',
}

const key = {
  GOOGLE_API_KEY: process.env.REACT_APP_GOOGLE_API_KEY,
  FRESHCHAT_TOKEN: process.env.REACT_APP_FRESHCHAT_TOKEN || '',
}

const config = {
  service,
  authService,
  social,
  restful,
  uploadService,
  key,
}

export default config
